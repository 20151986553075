import { ReleaseListRequest } from "@/domain/entities/Release";

export class ReleaseEndpoints {
  getReleaseList(params: ReleaseListRequest) {
    return `/hydra/v1/release?${params.toQueryString()}`;
  }
  updateBulkUpdateRelease() {
    return "/hydra/v1/release";
  }
  getReleaseDetail(id: any) {
    return `/hydra/v1/release/${id}`;
  }
}
