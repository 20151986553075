import { AxiosResponse } from "axios";
import { Pagination } from "@/domain/entities/Pagination";
import {
  ReleaseData,
  ReleaseDetailData,
  ReleaseDetailStt,
  ReleaseEntities,
} from "@/domain/entities/Release";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";

export class ReleaseMapper {
  public convertReleaseListDataFromApi(
    res: AxiosResponse<any>
  ): ReleaseEntities {
    const { data } = res;

    const release: ReleaseData[] = [];
    if (data.data.length === 0) {
      return new ReleaseEntities(
        new Pagination(
          data.meta.page,
          data.meta.limit,
          data.meta.total_records
        ),
        []
      );
    } else {
      data.data.map((item: any) => {
        release.push(
          new ReleaseData({
            releaseNo: item.release_no,
            releaseTotalWeight: item.release_total_weight,
            releaseTotalPieces: item.release_total_pieces,
            releaseCreatedAt: item.release_created_at,
            releaseCreatedBy: item.release_created_by,
            releaseCreatedName: item.release_created_name,
          })
        );
      });
    }
    return new ReleaseEntities(
      new Pagination(data.meta.page, data.meta.limit, data.meta.total_records),
      release
    );
  }
  public convertUpdateBulkReleaseDataFromApi(res: AxiosResponse<any>): ResponsePayloadV2 {
    const { data } = res;
    return new ResponsePayloadV2({
      success: data.success,
      message: data.message,
      data: {
        releaseNo: data.data.release_no,
        totalSTTSuccess: data.data.total_stt_success,
        totalSTTFailed: data.data.total_stt_failed,
        listSTTFailed: data.data.list_stt_failed
      }
    })
  }
  public convertReleaseDetailDataFromApi(
    res: AxiosResponse<any>
  ): ReleaseDetailData {
    const { data } = res;
    return new ReleaseDetailData({
      releaseNo: data.data.release_no,
      releaseTotalWeight: data.data.release_total_weight,
      releaseTotalPieces: data.data.release_total_pieces,
      releaseCreatedAt: data.data.release_created_at,
      releaseCreatedBy: data.data.release_created_by,
      releaseCreatedName: data.data.release_created_name,
      releaseTotalGoodsPrice: data.data.release_total_goods_price || 0,
      releaseDetailStt:
        data.data.release_detail_stt.length === 0
          ? []
          : data.data.release_detail_stt.map((item: any) => {
            return new ReleaseDetailStt({
              releaseBagNo: item.release_bag_no || "-",
              releaseSttNo: item.release_stt_no || "-",
              releaseDestinationCity: item.release_destination_city || "-",
              releaseOriginCity: item.release_origin_city || "-",
              releaseSttWeight: item.release_stt_weight || 0,
              releaseSttCommodityCode: item.release_stt_commodity_code || "-",
              releaseSttGoodsPrice: item.release_stt_goods_price || 0,
              releaseSttCommodityName: item.release_stt_commodity_name,
              releaseSttCommodityNameEn: item.release_stt_commodity_name_en
            })
          }),
    });
  }
}
