import { ReleaseMapper } from "@/data/persistences/mappers/ReleaseMapper";
import ApiService from "../../services/ApiService";
import { ReleaseEndpoints } from "../../endpoints/ReleaseEndpoint";
import { ReleaseRepositoryInterface } from "@/data/persistences/repositories/contracts/ReleaseRepositoryInterface";
import {
  ReleaseDetailData,
  ReleaseEntities,
  ReleaseListRequest,
} from "@/domain/entities/Release";
import { UpdateReleaseRequestInterface } from "@/data/payload/contracts/ReleaseRequest";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";

export class ReleaseApiRepository implements ReleaseRepositoryInterface {
  private service: ApiService;
  private mapper: ReleaseMapper;
  private endpoints: ReleaseEndpoints;

  constructor(
    service: ApiService,
    mapper: ReleaseMapper,
    endpoints: ReleaseEndpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }
  public async getReleaseDetail(id: any): Promise<ReleaseDetailData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getReleaseDetail(id),
      {}
    );
    return this.mapper.convertReleaseDetailDataFromApi(resp);
  }

  public async updateBulkUpdateRelease(payload: UpdateReleaseRequestInterface): Promise<ResponsePayloadV2> {
    const response = await this.service.invokePostWithFormData("post", 
    this.endpoints.updateBulkUpdateRelease(),
    {},
    payload
    )
    return this.mapper.convertUpdateBulkReleaseDataFromApi(response);
  }

  public async getReleaseList(
    params: ReleaseListRequest
  ): Promise<ReleaseEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getReleaseList(params),
      {}
    );
    return this.mapper.convertReleaseListDataFromApi(resp);
  }
}
