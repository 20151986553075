
import { AccountController } from "@/app/ui/controllers/AccountController";
import { Vue } from "vue-class-component";
import generateManifest from "./manifest-pdf";
import {
  formatPriceRP
} from "@/app/infrastructures/misc/Utils";
import { ReleaseDetailData } from "@/domain/entities/Release";
export default class PrintManifestRelease extends Vue {
  // get profile account
  get dataProfile() {
    return AccountController.accountData;
  }

  printManifest(dataDetail: ReleaseDetailData) {
    // logo
    const element: any = document;
    const logo = element.getElementById("logo")?.src;


    // handle data
    const data: any = [];

    dataDetail.releaseDetailStt.forEach((item, index) => {
      data.push({
        no: index + 1,
        bagNo: "",
        sttNo: "",
        grossWeight: "",
        goodsPrice: "",
        commodityType: "",
        originCity: "",
        destCity: "",
      });
      const bagNo = data[index].releaseBagNo ? "\n\n" : "";
      const sttNo = data[index].releaseSttNo ? "\n\n" : "";
      const grossWeight = data[index].releaseSttWeight ? "\n\n" : "";
      const goodsPrice = data[index].releaseSttGoodsPrice ? "\n\n" : "";
      const commodityType = data[index].releaseSttCommodityName ? "\n\n" : "";
      const originCity = data[index].releaseOriginCity ? "\n\n" : "";
      const destCity = data[index].releaseDestinationCity ? "\n\n" : "";

      data[index] = {
        ...data[index],
        bagNo: `${data[index].bagNo}${bagNo}${item.releaseBagNo}`,
        sttNo: `${data[index].sttNo}${sttNo}${item.releaseSttNo}`,
        grossWeight: `${data[index].grossWeight}${grossWeight}${item.releaseSttWeight} Kg`,
        goodsPrice: `${data[index].goodsPrice}${goodsPrice}${formatPriceRP(item.releaseSttGoodsPrice, "MYR")}`,
        commodityType: `${data[index].commodityType}${commodityType}${item.releaseSttCommodityName}`,
        originCity: `${data[index].originCity}${originCity}${item.releaseOriginCity}`,
        destCity: `${data[index].destCity}${destCity}${item.releaseDestinationCity}`,
      };
    });
    generateManifest(dataDetail, data, logo);
  }

  // role account user
  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }
}
