import { ConvertObjectCamelToSnakeCase, toFormDataUtils } from "@/app/infrastructures/misc/Utils";
import { UpdateReleaseRequestInterface } from "../contracts/ReleaseRequest";

export class BulkUpdateReleaseRequest implements UpdateReleaseRequestInterface {
    attachment = "";

    constructor(fields?: Partial<BulkUpdateReleaseRequest>) {
        Object.assign(this, fields);
    }

    public toFormData(): FormData {
        return toFormDataUtils(ConvertObjectCamelToSnakeCase(this));
    }

}