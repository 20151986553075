import { ReleasePresenter } from "@/app/ui/presenters/ReleasePresenter";
import { ReleaseMapper } from "@/data/persistences/mappers/ReleaseMapper";
import { container } from "tsyringe";
import { ReleaseEndpoints } from "../../endpoints/ReleaseEndpoint";
import { ReleaseApiRepository } from "../../repositories/api/ReleaseApiRepository";
import ApiService from "../../services/ApiService";

export class ReleaseComponent {
    public static init() {

        container.register<ReleaseApiRepository>(ReleaseApiRepository, {
            useFactory: d => {
                return new ReleaseApiRepository(
                    d.resolve(ApiService),
                    d.resolve(ReleaseMapper),
                    d.resolve(ReleaseEndpoints)
                );
            }
        });
        container.register<ReleaseMapper>(ReleaseMapper, {
            useClass: ReleaseMapper
        });
        container.register<ReleasePresenter>(ReleasePresenter, {
            useFactory: d => {
                return new ReleasePresenter(d.resolve(ReleaseApiRepository));
            }
        });
    }
}
