
import {
  formatDate,
  formatPriceRP,
  parsingErrorResponse,
} from "@/app/infrastructures/misc/Utils";
import { ReleaseController } from "@/app/ui/controllers/ReleaseController";
import { ApiStateData } from "@/domain/entities/Api";
import { ReleaseDetailData, ReleaseDetailStt } from "@/domain/entities/Release";
import { Options, Vue } from "vue-class-component";
import PrintManifestRelease from "../modules/print.vue";

@Options({
  components: {
    PrintManifestRelease,
  },
})
export default class Detail extends Vue {
  refs: any = "";
  mounted() {
    this.getDetailRelease();
    this.refs = this.$refs;
  }

  get getTitle() {
    return this.$route.params.id;
  }

  goBack() {
    this.$router.push("/release");
  }

  formatPrice(val: any) {
    return formatPriceRP(val, "MYR");
  }
  detailData = new ReleaseDetailData();
  apiDetailData = new ApiStateData();

  async getDetailRelease() {
    try {
      this.apiDetailData.loading = true;
      this.detailData = await ReleaseController.getReleaseDetail(this.$route.params.id);
      this.apiDetailData.errorType = "";
    } catch (error) {
      this.apiDetailData.errorType = "";
      this.apiDetailData.errorType =
        parsingErrorResponse(error).type === "internet-error"
          ? "internet"
          : parsingErrorResponse(error).type;
    } finally {
      this.apiDetailData.loading = false;
    }
  }

  get convertCreatedAt() {
    return formatDate(this.detailData.releaseCreatedAt);
  }

  columns = [
    {
      name: "No.",
      styleHead: "w-10 text-left whitespace-no-wrap",
      styleCustom: "align-top",
      render: (_: ReleaseDetailStt, index: number) => {
        return `<div class="text-black-lp-300">${index + 1}</div>`;
      },
    },
    {
      name: "No. Bag",
      styleHead: "w-48 text-left whitespace-no-wrap",
      styleCustom: "align-top",
      render: (item: ReleaseDetailStt) => {
        return `<div class="text-black-lp-300">${item.releaseBagNo}</div>`;
      },
    },
    {
      name: "No. STT",
      styleHead: "w-48 text-left whitespace-no-wrap",
      styleCustom: "align-top",
      render: (item: ReleaseDetailStt) => {
        return `<div class="text-black-lp-300">${item.releaseSttNo}</div>`;
      },
    },
    {
      name: "Berat",
      styleHead: "w-28 text-left whitespace-no-wrap",
      styleCustom: "align-top",
      render: (item: ReleaseDetailStt) => {
        return `<div class="text-black-lp-300">${item.releaseSttWeight} Kg</div>`;
      },
    },
    {
      name: "Harga Barang",
      styleHead: "w-40 text-left whitespace-no-wrap",
      styleCustom: "align-top",
      render: (item: ReleaseDetailStt) => {
        return `<div class="text-black-lp-300">${this.formatPrice(
          item.releaseSttGoodsPrice
        )}</div>`;
      },
    },
    {
      name: "Komoditas",
      styleHead: "w-40 text-left whitespace-no-wrap",
      styleCustom: "align-top",
      render: (item: ReleaseDetailStt) => {
        return `<div class="text-black-lp-300">${item.releaseSttCommodityName}</div>`;
      },
    },
    {
      name: "Origin City",
      styleHead: "w-28 text-left whitespace-no-wrap",
      styleCustom: "align-top",
      render: (item: ReleaseDetailStt) => {
        return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.releaseOriginCity}
                  </div>
                </div>`;
      },
    },
    {
      name: "Destination City",
      styleHead: "w-28 text-left whitespace-no-wrap",
      styleCustom: "align-top",
      render: (item: ReleaseDetailStt) => {
        return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.releaseDestinationCity}
                  </div>
                </div>`;
      },
    },
  ];

  printManifest() {
    this.refs.printManifest.printManifest(this.detailData);
  }
}
