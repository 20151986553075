import { injectable } from "tsyringe";
import { ReleaseApiRepository } from "@/app/infrastructures/repositories/api/ReleaseApiRepository";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { UpdateReleaseRequestInterface } from "@/data/payload/contracts/ReleaseRequest";
import { ReleaseDetailData, ReleaseEntities, ReleaseListRequest } from "@/domain/entities/Release";

@injectable()
export class ReleasePresenter {
  private repository: ReleaseApiRepository;

  constructor(repository: ReleaseApiRepository) {
    this.repository = repository;
  }

  public getListRelease(params: ReleaseListRequest): Promise<ReleaseEntities> {
    return this.repository.getReleaseList(params);
  }
  public updateBulkUpdateRelease(params: UpdateReleaseRequestInterface): Promise<ResponsePayloadV2> {
    return this.repository.updateBulkUpdateRelease(params);
  }
  public getReleaseDetail(id: any): Promise<ReleaseDetailData> {
    return this.repository.getReleaseDetail(id);
  }
}
