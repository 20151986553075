import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule
  } from "vuex-module-decorators";
  import store from "@/store";
  import {
    ReleaseEntities,
    ReleaseListRequest
  } from "@/domain/entities/Release";
  import { Pagination } from "@/domain/entities/Pagination";
  import { container } from "tsyringe";
  import { ReleasePresenter } from "../presenters/ReleasePresenter";
  import { ReleaseComponent } from "@/app/infrastructures/dependencies/modules/ReleaseComponent";
import { BulkUpdateReleaseRequest } from "@/data/payload/api/BulkUpdateReleaseRequest";
  ReleaseComponent.init();
  export interface ReleaseState {
    ReleaseList: ReleaseEntities;
  }
  
  @Module({ namespaced: true, dynamic: true, store, name: "Release" })
  class ReleaseStore extends VuexModule implements ReleaseState {
    public filter: any = {
      startDate: "",
      endDate: "",
      search: ""
    };
    public periodeStart: any = "";
    public periodeEnd: any = "";
    public ReleaseList = new ReleaseEntities(new Pagination(1, 20), []);
    public showFormUpdate = false;
  
    @Action
    public getReleaseList(params: ReleaseListRequest) {
      const presenter = container.resolve(ReleasePresenter);
      return presenter.getListRelease(params)
    }

    @Action
    public updateBulkUpdateRelease(params: BulkUpdateReleaseRequest) {
      const presenter = container.resolve(ReleasePresenter);
      return presenter.updateBulkUpdateRelease(params);
    }
    @Action
    public getReleaseDetail(id: any) {
      const presenter = container.resolve(ReleasePresenter);
      return presenter.getReleaseDetail(id);
    }

    @Mutation
    public setShowFormUpdate(value: boolean) {
      this.showFormUpdate = value;
    }
  
  
    @Mutation
    public setSearch(value: string) {
      this.filter.search = value;
      this.ReleaseList.pagination.page = 1;
    }
  
    @Mutation
    public setPeriodeStart(value: any) {
      this.periodeStart = value;
    }
  
    @Mutation
    public setPeriodeEnd(value: any) {
      this.periodeEnd = value;
    }
  }
  
  export const ReleaseController = getModule(ReleaseStore);
  