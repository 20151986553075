import { QueryParamsEntities } from "./MainApp";
import { Pagination } from "./Pagination";

export class ReleaseEntities {
    pagination: Pagination;
    data: ReleaseData[];
  
    constructor(pagination: Pagination, data: ReleaseData[]) {
      this.pagination = pagination;
      this.data = data;
    }
  }

  export class ReleaseData {
    releaseNo = "";
    releaseTotalWeight = 0;
    releaseTotalPieces = 0;
    releaseCreatedAt = "";
    releaseCreatedBy = "";
    releaseCreatedName = "";
    constructor(fields?: Partial<ReleaseData>) {
      Object.assign(this, fields);
    }
  }

  export class ReleaseListRequest {
    page = 1;
    limit = 10;
    startDate = "";
    endDate = "";
    search = "";
    constructor(fields?: Partial<ReleaseListRequest>) {
      Object.assign(this, fields);
    }
  
    toQueryString(): string {
      return new QueryParamsEntities(this).queryString;
    }
  }

  export class ReleaseDetailData {
    releaseNo = "";
    releaseTotalWeight = 0;
    releaseTotalPieces = 0;
    releaseTotalGoodsPrice = 0;
    releaseCreatedAt = "";
    releaseCreatedBy = "";
    releaseCreatedName = "";
    releaseDetailStt: ReleaseDetailStt[] = [];
    constructor(fields?: Partial<ReleaseDetailData>) {
      Object.assign(this, fields);
    }
  }

  export class ReleaseDetailStt {
    releaseBagNo = "";
    releaseSttNo = "";
    releaseOriginCity = "";
    releaseDestinationCity = "";
    releaseSttWeight = 0;
    releaseSttGoodsPrice = 0;
    releaseSttCommodityCode = "";
    releaseSttCommodityName = "";
    releaseSttCommodityNameEn = "";
    constructor(fields?: Partial<ReleaseDetailStt>) {
      Object.assign(this, fields);
    }
  }

  